import React, { useContext, useMemo } from 'react'
import { useLocation, useHistory } from 'react-router'
import { LANGUAGES } from '@apps/utils/constants'
import {
  APPLICATION_STATE,
  ApplicationContext,
  ModifyApplicationContext
} from '@apps/contexts/ApplicationContext'
import { getById, getImgByTitle, getBySlug } from '@apps/utils/contentful'
import { changeLang, changeRegion } from '@apps/utils/locale'
import { useDispatch, useSelector } from 'react-redux'
import { changeSelectedLanguage } from '@apps/redux/features/CommonSlice'
import Heading4 from '../Typography/Heading4'
import Body from '../Typography/Body'

const LanguageHeader = () => {
  const {
    content: { pageContent },
    user: { selectedLanguage }
  } = useSelector((state) => state.common)

  const content = useMemo(() => {
    const commonElements = getById(pageContent, 'commonElements')[selectedLanguage]
    return {
      check: getImgByTitle(commonElements, 'check')?.url,
      chevronDown: getImgByTitle(commonElements, 'chevronDown')?.url,
      header: getBySlug(commonElements, 'header')?.value,
      region: getBySlug(commonElements, 'region')?.value
    }
  }, [pageContent, selectedLanguage])
  
  const { language, region } = content

  const setAppState = useContext(ModifyApplicationContext)
  const appState = useContext(ApplicationContext)
  const {
    [APPLICATION_STATE.CURRENT_LANGUAGE]: currentLanguage,
    [APPLICATION_STATE.PROVINCES]: provinces,
    [APPLICATION_STATE.CURRENT_REGION]: currentRegion
  } = appState

  const location = useLocation()
  const history = useHistory()

  const dispatch = useDispatch()

  const updateAppState = (newLang) => {
    setAppState({
      ...appState,
      [APPLICATION_STATE.CURRENT_LANGUAGE]: newLang,
      [APPLICATION_STATE.SHOW_SELECT_LANGUAGE]: false
    })
    dispatch(changeSelectedLanguage(newLang))
  }

  return (
    <>
      {/* mobile/small screen layout */}
      <div className="block w-full md:hidden bg-cut1">
        <div className="max-w-2xl p-6 m-auto space-y-4">
          <div className="flex items-center justify-end">
            <div style={{ minWidth: "100px" }} className="text-right">
              <Heading4 className="pr-4 text-headerCopy">{language}</Heading4>
            </div>
            <select
              style={{
                backgroundImage: `url("${content?.chevronDown}")`,
                backgroundPosition: '96% 60%',
                backgroundRepeat: 'no-repeat'
              }}
              className="appearance-none font-body"
              placeholder={currentLanguage}
              value={currentLanguage}
              onChange={(e) => changeLang(e.target.value, location, history, updateAppState)}
            >
              {LANGUAGES.map((lang) => (
                <option className="ml-2" key={lang.value} value={lang.value}>
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-end">
            <div style={{ minWidth: "100px" }} className="text-right">
              <Heading4 className="pr-4 text-headerCopy">{region}</Heading4>
            </div>
            <select
              style={{
                backgroundImage: `url("${content?.chevronDown}")`,
                backgroundPosition: '96% 60%',
                backgroundRepeat: 'no-repeat'
              }}
              className="appearance-none font-body"
              placeholder={currentRegion}
              value={currentRegion}
              onChange={(e) => changeRegion(e.target.value, location, history, setAppState, appState)}
            >
              {provinces && provinces.map((prov) => (
                <option className="ml-2" key={prov.value} value={prov.shortName}>
                  {prov.label[currentLanguage]}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* desktop and large screen layout */}
      <div className="hidden w-full pt-6 pb-8 md:flex bg-cut1">
        <div className="flex w-full px-4 m-auto max-w-7xl">
          <div className="w-1/3 pr-6">
            <Heading4 className="text-headerCopy">{language}</Heading4>
            <div className="h-3 border-copy-inverted" style={{ borderBottom: '1px solid' }}></div>
            <div className="space-y-2 mt-7">
              {LANGUAGES.map((lang) => {
                return (
                  <div key={lang.value}>
                    <a
                      id={lang.value}
                      onClick={(e) => {
                        e.preventDefault()
                        changeLang(
                          lang.value,
                          location,
                          history,
                          updateAppState
                        )
                      }}
                      href=""
                    >
                      <Body className="flex items-center text-headerCopy hover:underline">
                        {lang.label}
                        {currentLanguage === lang.value && <img className="ml-3" src={content?.check} />}
                      </Body>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="w-2/3">
            <Heading4 className="text-headerCopy">{region}</Heading4>
            <div className="h-3 border-copy-inverted" style={{ borderBottom: '1px solid' }}></div>
            <div className="grid grid-cols-3 gap-2 mt-7">
              {provinces && provinces.map((prov) => {
                return (
                  <div key={prov.value}>
                    <a
                      id={prov.value}
                      onClick={(e) => {
                        e.preventDefault()
                        changeRegion(prov.shortName, location, history, setAppState, appState)
                      }}
                      href=""
                    >
                      <Body className="flex items-center text-headerCopy hover:underline">
                        {prov.label[currentLanguage]}
                        {currentRegion === prov.shortName && <img className="ml-3" src={content?.check} />}
                      </Body>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LanguageHeader
