import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CustomTab from '@apps/components/CustomTab'
import CustomTabItem from '@apps/components/CustomTab/CustomTabItem'
import { PLAN_DURATION } from '@apps/utils/constants'
import { useSelector } from 'react-redux'
import Sites from '@sites/index'
import { getById, getBySlug } from '@apps/utils/contentful'

const ChoosePlanSection = ({ onSelect, selectedOption }) => {
  const {
    content: { pageContent },
    user: { selectedLanguage },
    userType
  } = useSelector((state) => state.common)

  const isDealer = useMemo(() => {
    return userType === Sites.dealer
  }, [userType])

  const pagePrefix = isDealer ? 'Dealer' : 'Consumer'
  const textContent = useMemo(() => {
    const phoneNumberPageContent = getById(pageContent, `plansNumberPage${pagePrefix}`) || {}
    const { shortTextFields } = phoneNumberPageContent[selectedLanguage] || {}
    return {
      planDuration30days: getBySlug(shortTextFields, 'planDuration30days')?.value,
      planDuration360days: getBySlug(shortTextFields, 'planDuration360days')?.value
    }
  }, [pageContent, selectedLanguage])

  const { planDuration30days, planDuration360days } = textContent

  const onSelectHandler = (e) => {
    onSelect(e)
  }

  return (
    <CustomTab onSelect={onSelectHandler}>
      <CustomTabItem
        tabindex="0"
        role="tabpanel"
        id={PLAN_DURATION.SLOT_30}
        name={PLAN_DURATION.SLOT_30}
        selected={selectedOption === PLAN_DURATION.SLOT_30}
        data-testid="planDuration-30days"
      >
        <div
          className={`text-center text-sm md:text-base lg:text-medium ${
            selectedOption === PLAN_DURATION.SLOT_30 && 'font-bold'
          }`}
        >
          {planDuration30days}
        </div>
      </CustomTabItem>
      <CustomTabItem
        tabindex="0"
        role="tabpanel"
        id={PLAN_DURATION.SLOT_360}
        name={PLAN_DURATION.SLOT_360}
        selected={selectedOption === PLAN_DURATION.SLOT_360}
        data-testid="planDuration-360days"
      >
        <div
          className={`text-center text-sm md:text-base lg:text-medium ${
            selectedOption === PLAN_DURATION.SLOT_360 && 'font-bold'
          }`}
        >
          {planDuration360days}
        </div>
      </CustomTabItem>
    </CustomTab>
  )
}

ChoosePlanSection.propTypes = {
  onSelect: PropTypes.func,
  selectedOption: PropTypes.string
}

export default ChoosePlanSection
